<template>
  <div class="card card-custom gutter-b">
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">Pengaturan Sertifikat</h3>
      </div>
    </div>
    <div class="card-body">
      <!--begin::Example-->
      <div class="example mb-10">
        <!-- <h4 class="mb-10 font-weight-bold text-dark">Ubah Password</h4> -->
        <!--begin::Input-->
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          label="Nama Penandatangan"
          label-for="input-default"
        >
          <b-form-input
            id="input-default"
            type="text"
            required
            class="form-control"
            v-model="nama_penandatangan"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          label="Jabatan Penandatangan"
          label-for="input-default"
        >
          <b-form-input
            id="input-default"
            type="text"
            required
            class="form-control"
            v-model="jabatan_penandatangan"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          label="Direktorat Pelaksana"
          label-for="input-default"
        >
          <b-form-input
            id="input-default"
            type="text"
            required
            class="form-control"
            v-model="direktorat_pelaksana"
          ></b-form-input>
        </b-form-group>
        <div class="form-group row">
          <label class="col-lg-4 col-form-label"></label>
          <div class="col-lg-8">
            <button
              type="button"
              @click="onsubmit"
              style="float: right"
              class="btn btn-success font-weight-bolder text-uppercase px-9 py-4"
            >
              <i class="fas fa-save"></i> Simpan
            </button>
          </div>
        </div>
      </div>
      <!--end::Example-->
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from "axios";
import format_date from "@/core/utils/helper";
import Swal from "sweetalert2";

export default {
  name: "settingSertifikat",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Pengaturan Sertifikat" }]);
    this.loadData();
  },
  data() {
    return {
      url: localStorage.getItem("baseapi"),
      token: localStorage.getItem("id_token"),
      user: JSON.parse(localStorage.getItem("user")),
      nama_penandatangan: "",
      jabatan_penandatangan: "",
      direktorat_pelaksana: "",
    };
  },
  methods: {
    loadData() {
      axios
        .get(this.url + "/setting_sertifikat?id=1", {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          const sertifikat = response.data.data;
          this.nama_penandatangan = sertifikat.nama_penandatangan;
          this.jabatan_penandatangan = sertifikat.jabatan_penandatangan;
          this.direktorat_pelaksana = sertifikat.direktorat_pelaksana;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    onsubmit() {
      axios
        .put(
          this.url + "/setting_sertifikat/1",
          {
            nama_penandatangan: this.nama_penandatangan,
            jabatan_penandatangan: this.jabatan_penandatangan,
            direktorat_pelaksana: this.direktorat_pelaksana,
          },
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Data berhasil disimpan!",
          });
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Gagal",
            text: "Terjadi kesalahan saat menyimpan data.",
          });
        });
    },
  },
};
</script>
